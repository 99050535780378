<app-nav
  [page]="location">    
</app-nav>


<section class="container myAccount">
	<div class="row">
		<div class="col-xs-12">
			<h1>Your Account</h1>
			<!-- <p>On this page the user should be able to:</p>
			<ul>
				<li>Manage their email preferences</li>
				<li>See past purchases</li>
				<li>delete their account</li>
				<li>GDPR their data?</li>
			</ul> -->


			<div class="subscriptionInfo">

				<div *ngIf="userSecureData && userSecureData.subscription">
					<p>Subscription: {{userSecureData.subscription.status}}</p>

					<p *ngIf="userSecureData.subscription.cancel_at_period_end">Cancels {{ userSecureData.subscription.current_period_end*1000 | date}} </p>

					<p *ngIf="!userSecureData.subscription.cancel_at_period_end">Renews: {{ userSecureData.subscription.current_period_end*1000 | date}}.</p>

					<span (click)="openBillingUrl()" class="manageSub">Manage Subscription (opens Stripe dashboard)</span>

					<!-- <pre>
						{{userSecureData.subscription | json}}
					</pre> -->
	
				</div>
	
				<div *ngIf="!userSecureData ">
					<p>No subscription found</p>
				</div>

			</div>
		</div>

	</div>
</section>