
<div class="">
	
	<section>			

		<div *ngIf="!userData">
			<app-loader>    
			</app-loader>
		</div>

			<div class="row" style="max-width:80%;margin:auto" *ngIf="userData">
				
				<div class="col salesCol" *ngIf="!userSecureData || userSecureData?.subscription.status !== 'active'">

					<p>
						One-time purchase of $14.99 
					</p>

					<!-- <p>
						<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
						Deploy to Github or instant site download at any time
					</p>
					<p>
						<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
						Unlimited theme changes and updates
					</p>
					<p>
						<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
						"Watch my Github" - mydevportfol.io monitors your Github and reminds to update your portfolio when you add new public repositories
					</p>
					<p>
						<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
						Email notifcations of new themes and features
					</p>

					<div class="card price">
						<p *ngIf="!view.voucherApplied" class="main">
							$7.99<span class="perMonth">/month</span>
						</p>
						<p class="sub">
							Billed quarterly at $24.99
						</p>
						<p class="sub" style="font-weight: bold;">
							No penalty for cancellation - you can keep your website ;)
						</p> -->
	
						<!-- <form class="voucherForm">
	
							<p *ngIf="!view.voucherInput" class="voucherInputToggle" (click)="view.voucherInput=true">Use voucher code</p>
	
							<div *ngIf="view.voucherInput" class="form-group">
								<input type="text" class="form-control" id="voucherCode" placeholder="Enter Voucher Code" [(ngModel)]="voucherCode" (keyup)="voucherCodeKeyup()" name="voucherCode">
							</div>
	
							<span *ngIf="voucherCode.length">
								<p *ngIf="checkingVoucherCode">Checking discount code...</p>
								<p *ngIf="voucherMessage && !checkingVoucherCode">{{voucherMessage}}</p>
							</span>

						</form> -->

						<form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
							
							<!-- <div id="card-element"></div>	 -->
							<div id="stripe-card-number" #cardElementDiv></div>	
							<div id="stripe-card-expiry"></div>	
							<div id="stripe-card-cvc"></div>	
							<div *ngIf="view.showCardInput">
								
								<p *ngIf="view.paymentError">{{view.paymentError}}</p>
								<p *ngIf="view.cardError">{{view.cardError}}</p>
								
								<p *ngIf="view.loadingStripe">Working...</p>

								<input 
									*ngIf="!view.loadingStripe" 
									type="submit" value="Buy - $14.99" 
									style="width: 100%;"
									class="btn btn-success">
								

								<img src="/assets/Powered by Stripe - blurple.svg" alt="payments powered by stripe" width="100" class="powered-by-stripe-img">

							</div>

						</form>

						<div class="thankYou">
							<h4>Thank you from Catia & Chris</h4>
							<p>We're both developers. Every purchase gets us closer to our goal of building products for a living. Thank you 😊</p>
							<img src="/assets/chriscatia.jpeg" alt="payments powered by stripe" width="300" class="thankyouPhoto">
							<p class="caption">Both of us on a covid staycation in Co.Cork, Ireland, Aug 2021</p>
						</div>

				</div>
				<div *ngIf="userSecureData && userSecureData.subscription.status === 'active'">
					<div class="col">
						<div class="card">
							<div class="card-header">
								<img src="/assets/icons/github.svg" alt="" class="img-responsive icon">
								<h4>Deploy to Github Pages</h4>
							</div>
							<hr>
							<p>The easy option. Let mydevportfol.io deploy your site straight to GitHub</p>
							<div *ngIf="!deployRepo">
								<app-loader>    
								</app-loader>
							</div>
							<div class="col" *ngIf="deployRepo">
								<div class="btn-group deployRepoNameSelect" dropdown style="margin-bottom:7px">
									Select repository 
									<span dropdownToggle class="select" *ngIf="deployRepo">
											{{ deployRepo.name.toLowerCase()}}<span class="caret"></span>
									</span>
									<ul *dropdownMenu class="dropdown-menu" role="menu">
										<li class="dropdown-header">Select repo to deploy to</li>
										<li role="menuitem" *ngFor="let repo of userData.publicGithubRepos">
											<a class="dropdown-item" (click)="selectDeployRepo(repo)">{{repo.name.toLowerCase()}}</a>
										</li>
									</ul>
								</div>
								<p *ngIf="deployRepo.has_pages; else noPagesSetup" style="line-height: 24px;">
									<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
									Github pages has been set up for this repo, you're good to go.
									<br>
									<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
									This will update the content at
									<span *ngIf="userData.login.toLowerCase() === deployRepo.name.toLowerCase()">
										<a href="{{'https://'+deployRepo.name+'.github.io/'}}" target="_blank" rel="noreferrer">
											{{userData.login.toLowerCase()}}<span *ngIf="true">.github.io.</span>
										</a>
									</span>
									<span *ngIf="userData.login.toLowerCase() !== deployRepo.name.toLowerCase()">
										<a href="{{'https://'+userData.login+'.github.io/'+deployRepo.name}}" target="_blank" rel="noreferrer">
											{{userData.login.toLowerCase()}}.github.io/{{deployRepo.name}}
										</a>
									</span>
									<br>
									<span class="glyphicon glyphicon-ok" aria-hidden="true" style="color:green"></span>
									This change can always be undone with a revert.
								</p>
								<ng-template #noPagesSetup>
									<p>
										<span class="glyphicon glyphicon-remove" aria-hidden="true" style="color:red"></span>
										This repo does not have Github pages enabled.<br>
										This is necessary before proceeding. <br>
										<a href="https://pages.github.com/" target="_blank" rel="noreferrer">Check out how-to guide on Github</a>, then come back to proceed.
										<span *ngIf="!view.checkingGithub">
												<button class="btn btn-light btn-sm" (click)="getUserPublicRepos(userData.login)">Refresh Repos</button>
										</span>
										<span *ngIf="view.checkingGithub">
												Fetching repos...
										</span>
									</p>
								</ng-template>
							</div>
							<div class="deployBtn">
								<button 
									class="btn btn-success" 
									(click)="deployCode()" 
									*ngIf="deployRepo"
									[disabled]="!deployRepo.has_pages">Deploy to Github</button>
								<p *ngIf="view.githubDeployStatus">{{view.githubDeployStatus}}</p>
							</div>
						</div>
					</div>
					<div class="col">
						<div class="card">
							<div class="card-header">
								<img src="/assets/icons/html.svg" alt="" class="img-responsive icon">
								<h4>Download the HTML</h4>
							</div>
							<hr>
							<p>You'll get a single, simple, effective index.html page to use as your portfolio</p>

							<div style="width: 100%; text-align: center;">
								<button class="btn btn-success" (click)="downloadCode()">Download Code</button>
							</div>
	
							<!-- <p *ngIf="!view.voucherApplied" style="font-size:32px">${{deployPrice}}</p>
							<p *ngIf="view.voucherApplied" style="font-size:32px">
								<span class="strikethrough" style="font-size:">${{deployPrice}}</span>
								<br>
								${{salePrice}}
							</p>
							
	
							<form class="voucherForm">
	
								<p *ngIf="!view.voucherInput" class="voucherInputToggle" (click)="view.voucherInput=true">Enter voucher code</p>
	
	
								<div *ngIf="view.voucherInput" class="form-group">
									<input type="text" class="form-control" id="voucherCode" placeholder="Enter Voucher Code" [(ngModel)]="voucherCode" (keyup)="voucherCodeKeyup()" name="voucherCode">
								</div>
	
								<span *ngIf="voucherCode.length">
									<p *ngIf="checkingVoucherCode">Checking discount code...</p>
									<p *ngIf="voucherMessage && !checkingVoucherCode">{{voucherMessage}}</p>
								</span>
							</form>
	
							<button *ngIf="!view.showCardInput" class="btn btn-success" (click)="showCardInput()">Download code</button> -->
	<!-- 						<button *ngIf="view.voucherApplied" class="btn btn-success" (click)="showCardInput()">Download code <br><span class="strikethrough">{{deployPrice}}</span> ${{salePrice}}</button> -->
	
							<a href="#" id="downloadAnchorElem" style="display: none"></a>
	
						</div>
					</div>
				</div>
			</div>

	</section>
	
</div>


