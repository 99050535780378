<!-- <div class="animated" [ngClass]="{'scrolled': !atTopOfPage}"> -->
  <div class="animated" [ngClass]="{'fullWidth': fullWidth, 'smallWidth': !fullWidth}">
    <div class="navCol">

      <div class="logo" *ngIf="fullWidth">
        <a routerLink="/">
          <img src="https://mydevportfol.io/img/logo_transparent.png" alt="mydevportfol.io logo" width="100">
        </a>
      </div>
      
      <div class="navHolder">
        <button  *ngIf="userData && page != 'build'" routerLink="/build"  class="btn btn-success" style="margin-right:10px">To Builder</button>
        <!-- <button  *ngIf="userData && page == 'build'"   class="btn btn-success">Deploy!</button> -->
        <button *ngIf="!userData" class="btn btn-success" (click)="loginWithGithub()">Sign In</button>
        
        <div class="dropdownHolder">
          <div *ngIf="userData" class="dropdown">
            <div class="user_profile">
              <p style="margin-right:5px">Menu</p>
              <img  *ngIf="userData" [src]="userData.avatar_url" alt="" width="35" class="profile_photo">
            </div>
            <ul class="dropdown-content">
              <!-- <li><a routerLink="/build">To Builder</a></li> -->
              <li class="animated"><a routerLink="/myaccount">Manage Account</a></li>
              <li class="animated"><a href="" (click)="logout()">Log out</a></li>
            </ul>
          </div>
        </div>
      </div>

    </div>
  </div>
<!-- </div> -->