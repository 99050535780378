



<app-nav
  [page]="location">    
</app-nav>


<section style="display: flex;">

  <aside id="left-panel" [ngClass]="{'hider': !showPanel}">
    <div class="handHolding" *ngIf="showOnboarding">
      <h2>Get Started</h2>

      <p>Enter <a (click)="tabNum=1">your info</a> as a start. Then <a (click)="tabNum=2" >pick a theme</a>, and <a (click)="tabNum=3 ">add your projects</a></p>
      <p>Finally, you can deploy your portfolio either diretly to Github or by downloading the HTML file.</p>
      
      <button class="btn btn-success" (click)="hideOnboarding()">Got it</button>
    </div>
    <ul class="nav nav-tabs nav-justified">
      <li role="presentation" [class.active]="tabNum==1" (click)="tabNum = 1"><a>Info</a></li>
      <li role="presentation" [class.active]="tabNum==2" (click)="tabNum = 2"><a>Theme</a></li>
      <li role="presentation" [class.active]="tabNum==3" (click)="tabNum = 3"><a>Projects</a></li>
      <li role="presentation" [class.active]="tabNum==4" (click)="tabNum = 4">
        <a>Deploy</a>
      </li>
    </ul>
    <div class="panel-content" #reposElement>
      <!-- Deploy -->
      <div class="tab themes" *ngIf="tabNum==4">
        <app-deploy>
        </app-deploy>
      </div>
      <!-- Theme selection -->
      <div class="tab themes" *ngIf="tabNum==2">
        <!-- <p class="tab-heading">Pick your design</p> -->
        <div class="row theme-wrapper" *ngFor="let theme of themes">
          <!-- <div class="col-xs-5">
            <img [src]="theme.image" [alt]="theme.name" style="margin-bottom:10px" class="img-responsive">
          </div> -->
          <div class="col-xs-7">
            <div class="theme">
              <p class="name">{{ theme.name }}</p>
              <p class="description">{{ theme.description}}</p>
              <button *ngIf="theme.name !== userData.portfolio.theme" class="btn btn-sml btn-primary" (click)="selectTheme(theme)">Select</button>
              <button *ngIf="theme.name === userData.portfolio.theme" class="btn btn-sml btn-primary" disabled>Selected</button>
            </div>
          </div>
        </div>
      </div>
      <!-- Info input -->
      <div class="tab info" *ngIf="tabNum==1">
        <!-- <p class="tab-heading">Add your information and see your portfolio grow</p> -->
        <form class="form-horizontal infoForm" *ngIf="userData">
          <!-- profile pic -->
          <div class="form-group">
            <label for="user-profile-pic" class="control-label">Profile pic</label>
            <input (blur)="saveData()" type="text" class="form-control" id="user-profile-pic-url" placeholder="profile picture url" name="user-profile-pic-url" [(ngModel)]="userData.avatar_url">
            <span class="form-input-hint">Leave blank for no profile picture</span>
          </div>
          <!-- name -->
          <div class="form-group">
            <label for="user-name" class="control-label">Name</label>
            <input (blur)="saveData()" type="text" class="form-control" id="user-name" placeholder="Your name" name="user-name" [(ngModel)]="userData .name" autocomplete="given-name">
          </div>
          <!-- bio -->
          <div class="form-group">
            <label for="user-bio" class="control-label">Bio</label>
            <textarea (blur)="saveData()" class="form-control" id="user-bio" placeholder="a brief bio" name="user-bio" rows="4" [(ngModel)]="userData.bio"></textarea>
            <span class="form-input-hint">Leave blank for no bio</span>
          </div>
          <!-- location -->
          <div class="form-group">
            <label for="user-location" class="control-label">Location</label>
            <input (blur)="saveData()" class="form-control" id="user-location" placeholder="your location in the world" name="user-location" rows="2" [(ngModel)]="userData.location" autocomplete="address-level1">
            <span class="form-input-hint">Leave blank to stay location-anonymous</span>
          </div>
          <!-- email -->
          <div class="form-group">
            <label for="user-email" class="control-label">Email</label>
            <input (blur)="saveData()" class="form-control" id="user-email" placeholder="your email" name="user-email" rows="2" [(ngModel)]="userData.email" autocomplete="email">
            <span class="form-input-hint">Leave blank to not include your email on your site</span>
          </div>
          <!--   -->
          <div class="form-group">
            <label for="user-twitter" class="control-label">Twitter</label>
            <input (blur)="saveData()" class="form-control" id="user-twitter" placeholder="your twitter handle" name="user-twitter" rows="1" [ngModel]="userData.social?.twitter" (ngModelChange)="userData.social.twitter=$event" autocomplete="twitter">
            <span class="form-input-hint">Leave blank to not include your twitter on your site</span>
          </div>
          <!-- Linkedin -->
          <div class="form-group">
            <label for="user-Linkedin" class="control-label">Linkedin</label>
            <input 
              (blur)="saveData()" 
              class="form-control" id="user-Linkedin" 
              placeholder="your Linkedin link" 
              name="user-Linkedin"
              [(ngModel)]="userData.social.linkedin" 
              autocomplete="linkedin">
            <span class="form-input-hint">Leave blank to not include your Linkedin on your site</span>
          </div>
        </form>
      </div>
      <!-- project selection -->
      <div class="tab repos container-fluid" *ngIf="tabNum==3 && userData">
        <div class="row">
          <div class="col-xs-12">
            <h5>You have {{ userData.portfolio.projects.length }} projects in your portfolio</h5>
          </div>
        </div>
        <div *ngIf="userData.portfolio.projects">
          <div class="row project" [ngClass]="{'expanded': project.expanded}" *ngFor="let project of userData.portfolio.projects; let index = i; let first = first; let last = last" >
            <div class="col-xs-12">
              <div class="row">
                <div class="col-xs-9 projectTitle">
                  <span (click)="project.expanded = !project.expanded">
                    <h4>{{project.name || "My Project"}}</h4>
                    <span class="glyphicon glyphicon-chevron-down expandArrow" aria-hidden="true"></span>
                  </span>
                </div>
                <div class="col-xs-3 actions text-right">
                  <span 
                    class="glyphicon glyphicon-arrow-up" 
                    aria-hidden="true"  
                    (click)="moveProject(project, 'up')"
                    *ngIf="!first">
                  </span>
                  <span 
                    class="glyphicon glyphicon-arrow-down" 
                    aria-hidden="true" 
                    (click)="moveProject(project, 'down')"
                    *ngIf="!last">
                  </span>
                  <span 
                    class="glyphicon glyphicon-remove removeProject" aria-hidden="true" 
                    (click)="project.confirmDelete = true && project.expanded = true"
                    >
                  </span>
                </div>
              </div>
              <div class="row" *ngIf="project.confirmDelete">
                <div class="col-xs-12 confirmRemoveProjectCol">
                  <p>
                    Are you sure you want to remove this project?
                  </p>
                  <p class="buttons">
                    <button class="btn btn-warning" (click)="removeProject(project)">Yes, remove it</button>
                    <button class="btn btn-default" (click)="project.confirmDelete = false; project.expanded = false">No, cancel</button>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-xs-12">
                  <form class="form-horizontal projectForm" *ngIf="project.expanded">
                    <div class="row" *ngIf="project.image">
                      <div class="col-sm-12 center-block text-center">
                        <img [src]="project.image" alt="" class="project-img img-responsive center-block">
                      </div>
                    </div>
                    <!-- project pic -->
                    <div class="form-group">
                      <label for="project-{{i}}-image" class="control-label">Image URL</label>
                      <input type="text" class="form-control" id="project-{{i}}-image" placeholder="project image URL" name="project-{{i}}-image" [(ngModel)]="project.image" (blur)="saveData()">
                      <span class="form-input-hint">Leave blank to not display a project image</span>
                    </div>
                    <!-- Project Name -->
                    <div class="form-group">
                      <label for="project-{{i}}-name" class="control-label">Project Name</label>
                      <input type="text" class="form-control" id="project-{{i}}-name" placeholder="Project name" name="project-{{i}}-name" [(ngModel)]="project.name" (blur)="saveData()">
                    </div>
                    <!-- General Description -->
                    <div class="form-group">
                      <label for="project-{{i}}-description" class="control-label">Description</label>
                      <textarea class="form-control" id="project-{{i}}-description" placeholder="a brief general description, why you built this, and the stage it's currently at" name="project-{{i}}-description" rows="3" [(ngModel)]="project.description" (blur)="saveData()"></textarea>
                      <span class="form-input-hint">Leave blank to not show a description</span>
                    </div>
                    <div class="form-group">
                      <label for="inputLanguages" class="control-label">Technologies</label>
                      <div id="langHolder">
                        <span class="form-input-hint">
                          List all the technologies you used on this project.<br>
                          Can be left blank.
                        </span>
                        <input type="text" class="form-control" id="inputLanguages" placeholder="Add technology" name="newLanguage" [typeahead]="technologies" [(ngModel)]="project.technologyInput" (typeaheadOnSelect)="addTechToProject($event, project)">
                      </div>
                    </div>
                    <div class="form-group" id="knownLanguagesHolder">
                      <span class="knownTech" *ngFor="let tech of project.techUsed">{{tech}}
                      <span class="glyphicon glyphicon-remove removeTech" aria-hidden="true" (click)="removeTechFromProject(tech, project)"></span>
                      </span>
                    </div>
                    <div class="form-group">
                      <label for="project-{{i}}-url" class="control-label">Project URL</label>
                      <input type="text" class="form-control" id="project-{{i}}-url" placeholder="project link" name="project-{{i}}-url" [(ngModel)]="project.url" (blur)="saveData()">
                      <span class="form-input-hint">Add a link for people to check your project out. Can be left blank.</span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="btn no-shadow addProject" (click)="addProject()">Add Project Manually</button>

        <hr>
        <!-- Display Github repos -->
        <div class="row">
          <div class="col-xs-12">
            <img src="/assets/icons/github.svg" alt="" class="github_icon" width="25">
            <h5 class="github_projects_heading">Your Github projects</h5>
            <p>Last checked: 
              <span *ngIf="!view.checkingGithub">
                {{ (userData.lastCheckedGithub | date) || "unknown"}}
                <button class="btn btn-light btn-sm" (click)="getUserPublicRepos(userData.login)">Check Now</button>
              </span>
              <span *ngIf="view.checkingGithub">
                Fetching repos from Github...
              </span>
            </p>
          </div>
        </div>
        <div class="row project github_project" *ngFor="let project of userData.publicGithubRepos" >
          <div class="col-xs-12">
            <div class="row">
              <div class="col-xs-12 projectTitle">
                  <p>{{project.name}}</p>
                  <button class="btn btn-primary addGithubProject" (click)="addGithubProject(project)">Add</button>
              </div>  
            </div>
            <!-- <div class="row">
              <div class="col-xs-12">
                <p class="description">{{project.language}} - {{ project.description || "no description"}}</p>
              </div>
            </div> -->
          </div>
        </div>
 
      </div>
    </div>
    <span id="panelToggle" (click)="showPanel = !showPanel">
      <img src="/assets/icons/chevron.svg" alt="" class="img-responsive icon">
    </span>
  </aside>

  
  <div id="mainContent">
    <div class="loading" *ngIf="loading">
      <h4>Building your portfolio...</h4>
    </div>

    <div *ngIf="!loading">
      <div id="viewToggles">
        <span class="toggle" (click)="toggleFullscreen()">
          <i class="glyphicon glyphicon-fullscreen"></i>
        </span>
      </div>
      <div class="frame-holder">
        <div id="closeFullscreen" (click)="toggleFullscreen()" [ngClass]="{'visible': fullscreenIframe}" >
          <i class="glyphicon glyphicon-remove-circle"></i>
          <span>Close Fullscreen</span>
        </div>
        <iframe  [ngClass]="{'fullscreen': fullscreenIframe}" id="frame" width="100%" height="800" [srcdoc]="iframeContent | safe"></iframe>
      </div>
    </div>
  </div>

</section>



