
<div class="container">

		<app-nav
	    [page]="location">    
	  </app-nav>
	
	<section>
		
		<div class="row">
			<div class="col-xs-12 text-center content">
				
				<h1>Thank you!</h1>

				<p>I hope you love your new portfolio website. But what now? Well, you can put it wherever you want, whether it's your own personally hosted website, or somewhere else. The best thing to do (in my opinion) is to use GitHub pages, because it's easy and free to host your own one-page portfolio website (it's what I did to get my first developer job).</p>

				<p>For more info on how to do that, <a href="https://pages.github.com/" target="_blank">check out their documentation.</a> It's really easy to set up.</p>

				<p>If you've any feedback, positive or negative, hit me up in the chat bubble at the bottom of the page, or message me on Twitter <a href="https://twitter.com/cderm" target="_blank">@cderm</a></p>

				<p>Thanks!</p>
			</div>
		</div>

	</section>

	
</div>