


<div class="container">

  <app-nav
    [page]="location"
    (nextClicked)="nextClicked()">
  </app-nav>

  <!-- Primary header section, main CTA -->
  <section>

    <div class="row header">
      <div class="col-xs-12 text-center">
        <!-- <img src="./assets/logo.png" width="200" alt="mydevportfolio logo" class="img-responsive center-block"> -->
        <h2>Build a portfolio in minutes that gets you your next dev job</h2>
        <!-- <p>Normally <strong>$24.99</strong>, but use voucher <strong>JOBME</strong> at checkout for 50% discount for this month ;)</p> -->

        <button *ngIf="!user" class="btn btn-success btn-lg" (click)="loginWithGithub()">Sign in with Github</button>
        <button *ngIf="user" class="btn btn-success btn-lg" (click)="nextClicked()">Onwards to the builder!</button>
        <!--<span class="">Or</span>
        <a href="/build-1" class="btn btn-success btn-lg">Enter Data Manually</a> -->
      </div>
    </div>


    <!-- <div class="row">
      <div class="col-xs-12">
        <div class="primary-copy">
          <h2>But shouldn't I build my own portfolio?</h2>
          <p>Sure, if you're a frontend developer with the skills, experience and time to sit down, design it, build it, deploy it. </p>
          <p>But, then what are you losing. What's the opportunity cost of that time? Shouldn't you be off building websites for clients, or focusing on learning the latest JavaScript framework?</p>
          <p>Mydevportfol.io lets you get up and running ASAP with a portfolio that looks good and puts your work on the pedestal it deserves. </p>
        </div>
      </div> -->

<!--       <div class="col-xs-12 col-md-6">
        <img class="img-responsive" src="./assets/homer-angry.gif" alt="" width="400">
      </div> -->
    <!-- </div> -->



    <!-- Let's list out the portfolio previews, as per pages.xyz -->
    <div class="row designs">
      <div class="col-xs-12 text-center">
        <h2>Designs to choose from</h2>
        <!-- <p>Build one in minutes</p> -->
      </div>

      <div class="row">

        <div class="col-xs-12 col-sm-6" *ngFor="let theme of themes">
          <div class="design-holder" (click)="showThemePreview(theme)">
            <div class="design-heading">
              <span>{{theme.name}}</span>
            </div>
            <div class="design-image">
              <img [src]="theme.previewImage" alt="{{theme.name}} developer portfolio">
            </div>
          </div>
        </div>

      </div>
    </div>
    
    <div class="theme-full-preview" *ngIf="previewTheme">
      <div class="row">
        <div class="close-theme-preview" (click)="closeThemePreview()">
          <span class="glyphicon glyphicon-remove"></span>
        </div>
        
        <div class="col-md-9">
          <img class="img-responsive" [src]="previewTheme.previewImage" alt="">
        </div>
        <div class="col-md-3 theme-copy">
          <h3>{{previewTheme.name}}</h3>
          <p>{{previewTheme.description}}</p>
          <button *ngIf="!user" (click)="loginWithGithub()" class="btn btn-success" role="button" >Log in with Github</button>
          <button *ngIf="user" class="btn btn-success btn-lg" (click)="nextClicked()">Onwards to the builder!</button>
        </div>
      </div>
    </div>
      
<!-- 
    <div class="row why">
      <div class="col-xs-12">
        <div class="primary-copy">
          <h2>Price?</h2>
          <p>Free to try, I only ask for a donation if you want to download the code and use the site yourself.</p>
          <p>You can decide to pay as little as a coffee, or as much as you want - it's up to you.</p>
        </div>
      </div>
    </div> -->

    <!-- <div class="row why">
      <div class="col-xs-12">
        <div class="primary-copy">
          <h2>Seriously though, a generator?</h2>
          <p>The reason I built this tool is because I spent months, MONTHS building and tweaking my portfolio when I was looking for a job, which meant I wasn't building actual <em>projects</em> to put in the portfolio.</p>
          <p>So, in order to help other devs out there get a great-looking portfolio up and running so that they can get back to coding their projects, I built this app.</p>
          <p>It's in active development, so please feel free to reach out on twitter <a href="https://twitter.com/cderm" target="_blank">@cderm</a> and let me know your thoughts!</p>
          <p>Chris ;)</p>
        </div>
      </div>
    </div> -->


<!--     <div class="row updates">
      <div class="col-xs-12 text-center">

        <form class="form-inline" (ngSubmit)="subToList()" #mailchimpForm="ngForm">
          <input type="email" class="form-control" id="subscribe_email" placeholder="email" required [(ngModel)]="mailchimpForm.email" name="mailchimpEmail" required>
          <input type="submit" value="Subscribe" name="submit" id="" class="btn btn-success" [disabled]="!mailchimpForm.form.valid">
        </form>
        <p *ngIf="mailchimpRes">{{mailchimpRes}}</p>


      </div>
    </div> -->
  </section>



  <!-- Explaining why users should use the app -->
  <section>
    <div class="row how-it-works-row">
      <div class="col-xs-12 text-center">
        <h2>How it works</h2>
        <ol class="how-it-works-list">
          <li>Log in with Github. Mydevportfol.io uses your Github repos to build a portfolio for you - you can also manually add projects too</li>
          <li>Fill in your basic info. Your name, email etc</li>
          <li>Select the repositories that best represent your skills and talents to date</li>
          <li>Select from one of the pre-built designs. Preview the layout as it would be on your site</li>
          <li>After payment, you can download the file, or deploy your portfolio directly to a github pages enabled repository, making as many changes as you like</li>
        </ol>
      </div>
    </div>
    
  </section>


  

  <!-- Section for explaining the GitHub read/write permissions -->
  <!-- <section>
    <div class="row why-access-row">
      <div class="col-xs-12">
        <div class="why-access-content">
          <h2 id="whyAccess" class="text-center">What's with the Github access and permissions?</h2>
          <p>Mydevportfol.io uses your Github profile to make it really easy to create a portfolio showcasing your projects and repositories.</p>
          <p>Github only offers read/write permissions for private repositories, so we have to ask for both. We don't currently write anything to your Github, but in the future we might want to deploy your portfolio for you... that would be cool.</p>
        </div>
      </div>
    </div>
  </section> -->


  <section>
    <div class="row feedback-row">
      <div class="col-xs-12 text-center">
        <p>Got some feedback? Let me know on Twitter <strong><a href="https://twitter.com/cderm" target="_blank">@cderm</a></strong></p>
      </div>
    </div>
  </section>





</div>




